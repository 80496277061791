import { testIds } from "@decentriq/utils";
import { Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { type SxProps } from "@mui/system";
import { Fragment, memo } from "react";
import { Link } from "react-router-dom";
import { DataRoomsList } from "components";
import { Datalabs } from "features/dataLabs";
import { PublisherConfigurations } from "features/publisherPortal/components";
import { PublisherPortalWrapper } from "features/publisherPortal/contexts";
import { DataRoomTypeNames } from "models";

const tabsSx: SxProps = { flex: 1, overflow: "hidden" };
const tabPanelSx: SxProps = {
  "&:not([hidden])": {
    "--Tabs-spacing": 0,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
};

export enum PublisherPortalTabs {
  DATAROOMS = "datarooms",
  DATA_LABS = "datalabs",
  PUBLISHER_CONFIGURATIONS = "publisher-configurations",
}

interface PublisherPortalProps {
  activeTab: PublisherPortalTabs;
  baseUrl: string;
}

const PublisherPortal: React.FC<PublisherPortalProps> = memo(
  ({ activeTab, baseUrl }) => {
    return (
      <Fragment>
        <Typography level="h4" minHeight={68} p={2}>
          Publisher portal
        </Typography>
        <Tabs sx={tabsSx} value={activeTab}>
          <TabList>
            <Tab
              component={Link}
              data-testid={testIds.publisherPortal.tabList.dcrList}
              to={`${baseUrl}/${PublisherPortalTabs.DATAROOMS}`}
              value={PublisherPortalTabs.DATAROOMS}
            >
              DCRs
            </Tab>
            <Tab
              component={Link}
              data-testid={testIds.publisherPortal.tabList.dataLabs}
              to={`${baseUrl}/${PublisherPortalTabs.DATA_LABS}`}
              value={PublisherPortalTabs.DATA_LABS}
            >
              Datalabs
            </Tab>
            <Tab
              component={Link}
              data-testid={
                testIds.publisherPortal.tabList.publisherConfigurations
              }
              to={`${baseUrl}/${PublisherPortalTabs.PUBLISHER_CONFIGURATIONS}`}
              value={PublisherPortalTabs.PUBLISHER_CONFIGURATIONS}
            >
              Configurations
            </Tab>
          </TabList>
          <TabPanel sx={tabPanelSx} value={PublisherPortalTabs.DATAROOMS}>
            <DataRoomsList
              exposedDataRoomTypes={[
                DataRoomTypeNames.PublishedMediaDataRoom,
                DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
                DataRoomTypeNames.PublishedMediaInsightsDcr,
              ]}
            />
          </TabPanel>
          <TabPanel sx={tabPanelSx} value={PublisherPortalTabs.DATA_LABS}>
            <Datalabs />
          </TabPanel>
          <TabPanel
            sx={tabPanelSx}
            value={PublisherPortalTabs.PUBLISHER_CONFIGURATIONS}
          >
            <PublisherPortalWrapper>
              <PublisherConfigurations />
            </PublisherPortalWrapper>
          </TabPanel>
        </Tabs>
      </Fragment>
    );
  }
);
PublisherPortal.displayName = "PublisherPortal";

export default PublisherPortal;
