import { memo } from "react";
import { Navigate, Outlet, useResolvedPath, useRoutes } from "react-router-dom";
import { DatalabWithIdFromParams } from "features/dataLabs";
import { PublisherPortal, PublisherPortalTabs } from "features/publisherPortal";

const PublisherPortalPage = memo(() => {
  const resolvedPath = useResolvedPath("");
  const url = resolvedPath.pathname;
  const element = useRoutes([
    {
      element: (
        <PublisherPortal
          activeTab={PublisherPortalTabs.DATAROOMS}
          baseUrl={url}
        />
      ),
      path: PublisherPortalTabs.DATAROOMS,
    },
    {
      children: [
        {
          element: (
            <PublisherPortal
              activeTab={PublisherPortalTabs.DATA_LABS}
              baseUrl={url}
            />
          ),
          path: "",
        },
        {
          element: <DatalabWithIdFromParams />,
          path: ":datalabId",
        },
      ],
      element: <Outlet />,
      path: `${PublisherPortalTabs.DATA_LABS}/*`,
    },
    {
      element: (
        <PublisherPortal
          activeTab={PublisherPortalTabs.PUBLISHER_CONFIGURATIONS}
          baseUrl={url}
        />
      ),
      path: PublisherPortalTabs.PUBLISHER_CONFIGURATIONS,
    },
    {
      element: (
        <PublisherPortal
          activeTab={PublisherPortalTabs.DATA_LABS}
          baseUrl={url}
        />
      ),
      path: PublisherPortalTabs.DATA_LABS,
    },
    {
      element: <Navigate to={`${url}/${PublisherPortalTabs.DATAROOMS}`} />,
      path: "/",
    },
    {
      element: <Navigate to={`${url}/${PublisherPortalTabs.DATAROOMS}`} />,
      path: "*",
    },
  ]);
  return element;
});
PublisherPortalPage.displayName = "PublisherPortalPage";

export default PublisherPortalPage;
