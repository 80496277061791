import { testIds } from "@decentriq/utils";
import { faDatabase } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";
import { useOrganizationPreferences } from "hooks";

const DataPartnerPortalMenuItem = memo(() => {
  const { hasPublisherFeatures } = useOrganizationPreferences();
  return (
    <SidebarMenuItem
      data-testid={testIds.sidebar.sidebarMenuItems.dataPartnerPortalButton}
      icon={faDatabase}
      innerMenuItems={[
        { label: "DCRs", to: "/data-partner-portal/datarooms" },
        ...(hasPublisherFeatures
          ? []
          : [{ label: "Datalabs", to: "/data-partner-portal/datalabs" }]),
        { label: "Configuration", to: "/data-partner-portal/configurations" },
      ]}
      label="Data Partner Portal"
      to="/data-partner-portal"
      tooltipTitle="Data Partner Portal"
    />
  );
});

DataPartnerPortalMenuItem.displayName = "DataPartnerPortalMenuItem";

export default DataPartnerPortalMenuItem;
