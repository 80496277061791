import { DqLoader } from "@decentriq/components";
import { Stack } from "@mui/joy";
import React, { Fragment, memo } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  DataLabErrorReport,
  DataLabNodes,
  DataLabQualityStatistics,
  DataLabToolbar,
  useDatalabsBaseUrl,
} from "features/dataLabs";
import { DataLabWrapper, useDataLabContext } from "features/dataLabs/contexts";

interface DatalabProps {
  id: string;
}

const Datalab: React.FC<DatalabProps> = ({ id }) => {
  const datalabsBaseUrl = useDatalabsBaseUrl();
  const {
    dataLab: { data, loading },
  } = useDataLabContext();
  if (loading) {
    return (
      <DqLoader
        size="sm"
        sx={{ height: "100%", justifyContent: "center", width: "100%" }}
      />
    );
  }
  if (data) {
    return (
      <Fragment>
        <DataLabToolbar id={id} />
        <Stack direction="column" sx={{ flex: 1, overflow: "auto", p: 2 }}>
          <DataLabNodes />
          <DataLabQualityStatistics />
        </Stack>
      </Fragment>
    );
  }
  return <Navigate replace={true} to={datalabsBaseUrl} />;
};

const DatalabPageWrapper = memo<DatalabProps>(({ id }) => {
  return (
    <DataLabErrorReport id={id}>
      <DataLabWrapper id={id}>
        <Datalab id={id} />
      </DataLabWrapper>
    </DataLabErrorReport>
  );
});

export default DatalabPageWrapper;

export const DatalabWithIdFromParams = memo(() => {
  const { datalabId } = useParams();
  return <Datalab id={datalabId!} />;
});
DatalabWithIdFromParams.displayName = "DatalabWithIdFromParams";
